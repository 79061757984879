import React from "react";
import { getFromLocalStorage } from "./helper";
import { LOCAL_STORAGE_KEYS, APP_SOURCE } from "./app-constants";

export const currentEnv = process.env.REACT_APP_HOST_ENV;

export const appUrl = () => {
    const urls = {
        QA: "",
        LNOD: "",
        STAGING: "",
        PRODUCTION: ""
    };
    return urls[currentEnv];
};

export const apiUrl = () => {
    const selectedBaseAPIHostKey = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_BASE_API_HOST);
    let urls ={};
    if (selectedBaseAPIHostKey === APP_SOURCE.BIKE){
        urls = {
            QA: "https://bikes-lms-qa.ninja24.in", 
            LNOD: "https://bikes-lms-qa.ninja24.in",
            STAGING: "https://lms-staging.c24svc.dev",
            PRODUCTION: "https://bikes-lms.24c.in"
        };
    }
    else {
        urls = {
            QA: "https://lms-qa1.qac24svc.dev",
            LNOD: "http://lms-lnod.ninja24.in",
            STAGING: "https://lms-staging.c24svc.dev",
            PRODUCTION: "https://lms.24c.in"
        };
    }
    return urls[currentEnv];
};

export const getVehicleServiceMetaData = () => {
    const metaData = {
        QA: {url: "https://vehicle-service.qac24svc.dev", key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ"},
        STAGING: {url: "https://stage-vehicle-service.qac24svc.dev", key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ="},
        PRODUCTION: {url: "https://vehicle.cars24.team", key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi"},
    };
    return metaData[currentEnv];
};

export const getPartnersLeadMetaData = () => {
    const metaData = {
		QA: {
		  url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/partners-lead",
		  key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
		},
		STAGING: {
		  url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/partners-lead",
		  key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ=",
		},
		PRODUCTION: {
		  url: "https://gateway.24c.in/partners-lead",
		  key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi",
		},
	  };
    return metaData[currentEnv];
};

export const getJavaLeadServiceMetaData = () => {
    const metaData = {
        QA: {url: "https://lead-service-java.qac24svc.dev", key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ"},
        STAGING: {url: "https://stage-lead-service-java.qac24svc.dev", key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ="},
        PRODUCTION: {url: "https://seller-lead.cars24.team", key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi"},
    };
    return metaData[currentEnv];
};

export const getCarApiMetaData = () => {
    const metaData = {
        QA: {url: "https://api-se-qa1.qac24svc.dev/v2", key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ"},
        STAGING: {url: "https://api-staging.qac24svc.dev", key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ="},
        PRODUCTION: {url: "https://api.cars24.com", key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi"},
    };
    return metaData[currentEnv];
};

export const getStreamConfig = () => {
    const data = {
        QA: {appKey: "v2gvnz2edwzj", channelType: "rachat", url: "https://chat-proxy-singapore.stream-io-api.com", channelCount: 50},
        LNOD: {appKey: "m2axsrtv6s97", channelType: "rachat", url: "https://chat-proxy-singapore.stream-io-api.com", channelCount: 50},
        STAGING: {appKey: "sxkpbtu4jt98", channelType: "rachat" , url: "https://chat-proxy-singapore.stream-io-api.com", channelCount: 50},
        PRODUCTION: {appKey: "y4cpj35aqxwb", channelType: "rachat", url: "https://chat-proxy-singapore.stream-io-api.com", channelCount: 50},
    };
    return data[currentEnv];
};

export const imageURLAppender = () => {
    const data = {
        QA: "",
        LNOD: "",
        STAGING: "unsafe/360x360/filters:watermark(/models/logo_watermark_cross.png,0,0,90):quality(100)/",
        PRODUCTION: ""
    };
    return data[currentEnv];
};

export const thumborUrl = () => {
    const urls = {
        QA: "http://thumbor-inspection1.ninja24.in",
        LNOD: "http://thumbor-inspection1.ninja24.in",
        STAGING: "https://thumbor-staging.24c.in",
        PRODUCTION: "https://fastly-production.24c.in"
    };
    return urls[currentEnv];
};

export const apiAuthKey = () => {
    const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    const authKey = loginData ? JSON.parse(loginData).apiAuthKey : "";
    return authKey;
};

export const getCLientId = () => {
    const urls = {
        QA: "114457882658-ujnu74qihv343fpkutm76fu331g5p00a.apps.googleusercontent.com",
        LNOD: "114457882658-ujnu74qihv343fpkutm76fu331g5p00a.apps.googleusercontent.com",
        STAGING: "114457882658-ujnu74qihv343fpkutm76fu331g5p00a.apps.googleusercontent.com",
        PRODUCTION: "546263658957-aajfmar1p755diqeih2nhno37f0udf6n.apps.googleusercontent.com"
    };
    return urls[currentEnv];
};

export const getFireBaseDB = () => {
    const clinetIds = {
        QA: "customerdashboardd",
        STAGING: "lms-cars24-d9fc3-stage",
        PRODUCTION: "lms-cars24-ac0f3-prod"
    };
    return clinetIds[currentEnv];
};

export const getFireBaseUserName = () => {
    const clinetIds = {
        QA: "lms.dasboard@gmail.com",
        STAGING: "lms.dasboard@gmail.com",
        PRODUCTION: "lms.dasboard@gmail.com"
    };
    return clinetIds[currentEnv];
};

export const getFireBasePassword = () => {
    const clinetIds = {
        QA: "#@12jkhrt&*",
        STAGING: "#@12jkhrt&*",
        PRODUCTION: "#@12jkhrt&*"
    };
    return clinetIds[currentEnv];
};

export const getGoogleMapKey = () => {
    const clinetIds = {
        QA: "AIzaSyDmpfxakAGCqOrkABOIGE6S4pH0aqsftDA",
        STAGING: "AIzaSyDmpfxakAGCqOrkABOIGE6S4pH0aqsftDA",
        PRODUCTION: "AIzaSyBFRLfm2hUnt3Qh63L1C6dheSyJL_kcsKk"
    };
    return clinetIds[currentEnv];
};

export const getConsentChargesBaseApiUrl = () => {
    const baseUrls = {
        QA: 'https://consumer-service.qac24svc.dev',
        STAGING: 'https://stage-consumer-service.qac24svc.dev',
        PRODUCTION: 'https://consumer-service.cars24.team'
    };
    return baseUrls[currentEnv];
};
export const getSentryConfig = () => {
    const configs = {
        PRODUCTION: {dsn: "https://555c55a17df34cf9ad12c58788b27920@o283277.ingest.sentry.io/4505385267494912", rate: 0.1},
        STAGING: {dsn: "https://cc293e42dc5645d7ac19df19803a64b4@o4505387174723584.ingest.sentry.io/4505387178262528", rate: 1.0}
    };
    return configs[currentEnv];
};

export const getSupersetMetaData = () => {
    const configs = {
        QA: {key:'43', url: 'https://superset-prod.cars24.team'},
        STAGING: {key:'43', url: 'https://superset-prod.cars24.team'},
        PRODUCTION: {key:'43', url: 'https://superset-prod.cars24.team'}
    };
    return configs[currentEnv];
};

export const getPortalHostUrl = () => {
    const metaData = {
        QA: "https://c2b-panel-internal.qac24svc.dev",
        STAGING: "https://stage-c2b-portal.qac24svc.dev/",
        PRODUCTION:  "https://portal-v2.24c.in/", 
    };
    return metaData[currentEnv];
};

export const getB2BTenantId = () => {
	const metaData = {
	  QA: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
	  STAGING: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
	  PRODUCTION: "T2ZOSj4E10QQuONVCOXFngrGzHZk",
	};
	return metaData[currentEnv];
};

export const getLocationServiceMetaData = () => {
	const metaData = {
		QA: {
		url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/location-service",
		key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
		},
		STAGING: {
		url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/location-service",
		key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ=",
		},
		PRODUCTION: {
		url: "https://gateway.24c.in/location-service",
		key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi",
		},
	};
	return metaData[currentEnv];
};